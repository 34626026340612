import React from "react";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
	return (
		browser && (
			<div className="my1 py1 mxa ac serif serif--variable-medium lower">
				Page Not Found.
			</div>
		)
	);
};

export default NotFoundPage;
